import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { t } from "i18next";
import { useSnackbar } from "notistack";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import { DATA_GRID_FOOTER_HEIGHT, DATA_GRID_NO_ROWS_HEIGHT } from "../../../config/style";
import { Chart } from "../../../types";
import { dashboardApi, DashboardIndividualListGetRequest } from "../dashboard-api";

type DashboardIndividualListTableProps = {
  individualListFilter: DashboardIndividualListGetRequest;
  chart: Chart;
};

const INDIVIDUAL_LIST_DIALOG_PAGE_SIZE = 10;

export const DashboardIndividualListTable = ({ individualListFilter, chart }: DashboardIndividualListTableProps) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const useIndividualListQuery = dashboardApi.useIndividualList(chart, individualListFilter);
  const { getProfileUrl, isLoading: isUrlLoading } = dashboardApi.useGetProfileUrl();
  const [profileOpeningEmployees, setProfileOpeningEmployees] = useState<string[]>([]);

  const columns: GridColDef[] = [
    {
      field: "employeeNumber",
      headerName: t("individual-list.employee-number") as string,
      flex: 1,
      maxWidth: 150,
    },
    {
      field: "name",
      headerName: t("individual-list.employee-name") as string,
      flex: 1,
      renderCell: ({ row }) => {
        return row.existCydas ? (
          <EmployeeNameLink
            workspaceId={row.workspaceId}
            employeeNumber={row.employeeNumber}
            profileOpeningEmployees={profileOpeningEmployees}
            name={row.name}
            onClick={handleClickEmployeeName}
            isLoading={isUrlLoading}
          />
        ) : (
          <Typography>{row.name}</Typography>
        );
      },
    },
  ];
  const handleClickEmployeeName = ({
    workspaceId,
    employeeNumber,
  }: {
    workspaceId: string;
    employeeNumber: string;
  }) => {
    setProfileOpeningEmployees([...profileOpeningEmployees, employeeNumber]);
    getProfileUrl({
      workspaceId,
      employeeNumber,
      onSuccess: (url) => {
        window.open(url, "_blank");
      },
      onFail: (i18nKey) => {
        enqueueSnackbar(t(i18nKey), { variant: "error" });
      },
      onFinally: () => {
        setProfileOpeningEmployees(profileOpeningEmployees.filter((num) => num !== employeeNumber));
      },
    });
  };

  const getRowsWithId = () => {
    if (useIndividualListQuery.data && !useIndividualListQuery.isLoading) {
      return useIndividualListQuery.data.map((row, index) => ({
        employeeNumber: row.employeeNumber,
        name: row.name,
        workspaceId: row.workspaceId,
        existCydas: row.existCydas,
        id: index,
      }));
    }
    return [];
  };

  return (
    <Grid item xs={12} mt={1}>
      <DataGrid
        sx={{
          "& .MuiDataGrid-cell:focus, & .MuiDataGrid-cell:focus-within": {
            outline: "none",
          },
          "& .MuiDataGrid-columnHeader:focus, & .MuiDataGrid-columnHeader:focus-within": {
            outline: "none",
          },
          "& .MuiDataGrid-footerContainer": {
            height: DATA_GRID_FOOTER_HEIGHT,
            minHeight: DATA_GRID_FOOTER_HEIGHT,
          },
          "& .MuiDataGrid-row:nth-of-type(1) .MuiDataGrid-cell": {
            borderTop: "none",
          },
          minHeight: DATA_GRID_NO_ROWS_HEIGHT,
        }}
        columns={columns}
        columnHeaderHeight={35}
        rows={getRowsWithId()}
        rowSelection={false}
        rowHeight={35}
        disableRowSelectionOnClick
        disableColumnMenu
        disableColumnSelector
        disableColumnSorting
        autoHeight
        initialState={{
          pagination: { paginationModel: { pageSize: INDIVIDUAL_LIST_DIALOG_PAGE_SIZE } },
        }}
        loading={useIndividualListQuery.isLoading}
        slots={{
          noRowsOverlay: () => (
            <Box height={DATA_GRID_NO_ROWS_HEIGHT} display="flex" justifyContent="center" alignItems="center">
              <Typography color={(theme) => theme.palette.grey[500]}>{t("no-data")}</Typography>
            </Box>
          ),
        }}
      />
    </Grid>
  );
};
const EmployeeNameLink = ({
  name,
  employeeNumber,
  profileOpeningEmployees,
  workspaceId,
  isLoading,
  onClick,
}: {
  name: string;
  employeeNumber: string;
  profileOpeningEmployees: string[];
  workspaceId: string;
  isLoading: boolean;
  onClick: ({ workspaceId, employeeNumber }: { workspaceId: string; employeeNumber: string }) => void;
}) => {
  const handleClick = () => {
    onClick({ workspaceId, employeeNumber });
  };
  return (
    <Stack direction="row" alignItems="center" spacing={1}>
      <Box>
        <Link
          component="button"
          underline="hover"
          disabled={profileOpeningEmployees.includes(employeeNumber)}
          onClick={handleClick}
        >
          <Stack direction="row" alignItems="center">
            <Typography>{name}</Typography>
            <OpenInNewIcon fontSize="small" sx={{ mb: 0.125 }} />
          </Stack>
        </Link>
      </Box>
      {isLoading && profileOpeningEmployees.includes(employeeNumber) && (
        <Box>
          <Stack direction="row" spacing={1}>
            <CircularProgress color="inherit" size={18} />
            <Typography>{t("individual-list.open-cydas-profile") as string}</Typography>
          </Stack>
        </Box>
      )}
    </Stack>
  );
};
